<template>
  <div @click="$services['MODULE-App-UiColorSchema'].api.setCurrentSchema(schema)" style="cursor: pointer; border: 1px solid #c0c0c0; border-radius: 8px; padding: 12px; display: inline-block; margin-right: 10px;" :class="schema.cssClasses">
    <h4 style="font-size: 12pt;">{{schema.name}}</h4>
  </div>
</template>
<script>
export default {
  props: {
    schema: {
      required: true
    }
  },
  data () {
    return {
      _name: null,
      _previewColor: null
    }
  }
}
</script>
